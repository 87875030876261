import React from 'react';
import * as styles from './pagination.module.scss';

const Pagination = ({ dots, index }) => {

    const showDots = dots => {
        let content = [];
        for (let i= 0; i < dots; i++){
            content.push(<span className={`${styles.dots} ${index === i && styles.active}`}></span>);
        }
        return content;
    }

    return (
        <div className={styles.pagination}>
            {showDots(dots)}
        </div>
    );
};

export default Pagination;
