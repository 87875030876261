import React, { useEffect, useState } from "react";
import FlexBanner from "../components/atoms/FlexBanner/FlexBanner";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import PdfSection from "../components/atoms/PdfSection/PdfSection";
import ModulesExplore from "../components/molecules/ModulesExplore";
import ModulesArticle from "../components/ogranisms/ModulesArticle/ModulesArticle";
import ModulesManuals from "../components/ogranisms/ModulesManuals/ManualsSection";
import * as styles from "./styles.module.scss";
import bg from "../images/bg.jpeg";
import Values from "../components/molecules/Values";
import { values } from "../constants/aboutUsValues";
import Head from "../components/ogranisms/head";
import WhyBanner from "../components/ogranisms/WhyBanner";
import AOS from "aos";
import "aos/dist/aos.css";
import ModulesGrid from "../components/ogranisms/modulesGrid/ModulesGrid";
function ModulesY() {
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration : 1500
    });
  }, []);
  const [width, setWidth] = useState(0);
  useEffect(() => {
     setWidth(window.innerWidth);
  });
  return (
    <>
      <Head
        title="Modules - MASTER WiZR"
        description="Welcome to the WiZR World , better than real"
        ogTitle="Modules"
      />
      <main>
        <Header />
        <FlexBanner
          extraClass={"columnContainer"}
          title={"Product Modules"}
          image={width>=768 ? "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680113654/web%20images/WiZRConnect_Meeting_4_eiddr0.png" : "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1683329686/loginicons_2_1_1_jsweun.png"}
          desc={width >=768 ? "<p>Start with small teams or departments and gradually expand their use.</p>" : null}
        />
        {/* <ModulesGrid /> */}
        <ModulesExplore />
        <FlexBanner
          extraClass={"loginSection"}
          title={`Workspace <br/> <span>Eco System</span> `}
          image={width>=768 ? "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680114263/web%20images/loginicons_1_1_yelocy.png" : "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680902802/web%20images/leftpanel1-2976e318bde8e7a4c2c46b83752565db_ibhaaq.png"}
          desc={width>=768 ? 
            `
            <br/> <br/> <br/>
            <ul><li><b>Library</b> - Organize and share content</li>
            <li><b>Studio</b> - Prepare material for meetings</li>
            <li><b>WiZR Connect</b> - Next Gen video conference</li>
            <li><b>MPublisher</b> - Create presentations</li>
            <li><b>WiZRX</b> - Connect and update news</li>

            </ul> <br/>
            <p>Professional license holders will receive free access to WiZRX and MPublisher when the Beta-stage is released in spring 2023. </p>
            `
            :
            `
            <br/> <br/> <br/>
            <ul><li><b>Library</b> - Organizing and sharing content</li>
            <li><b>Studio</b> - Preparing material for meetings</li>
            <li><b>WiZR Connect</b> - Next Gen video conferencing </li>
            <li><b>MPublisher</b> - Create presentations</li>
            <li><b>WiZRX</b> -  Connect and update news/li>

            </ul> <br/>
            `
          }
        />
        <FlexBanner
          extraClass={"portalsSection"}
          title={"<span>Portals</span> - Material that matters"}
          bgImage={bg}
          image={width>=768 ? "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680114587/web%20images/Portals_U_3_1_1_s6ezqn.png" :"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680114937/web%20images/Portals_U_1_1_1_rinmcl.png"}
          desc={width>=768 ? "<p> Organize your library with Portal folders. Our Master Programs make it easy to get started with internal or external material, for one or multiple departments.</p>" : "<p> Create portal folders in Library for internal/external material. Master Programs help you get started.</p>"}
        />
        <FlexBanner
          extraClass={"portalsFlex2"}
          title={`Creative & Practical uses `}
          image={width>=768 ? "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680118993/web%20images/StreamingView_Updated_3_1_1_gvy9qc.png" : "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680296707/web%20images/Usercases_13_xvr7zs.png"}
          desc={width >= 768 ? 
            `<p>In our Studio and Streaming View,  discover a wide selection of applications designed for creating impactful material</p> <br/> <br/>
            <ul>
            <li>Sponsor Material</li>
            <li>Corporate Strategy</li>
            <li>Campaign Material</li>
            <li>Product Catalogues</li>
            <li>Meeting Slide Decks</li>
            <li>Manuals</li>
            <li>Annual Reports</li>
            <li>Bid Material</li>
            </ul>
            `
            :
            `<p>In our Studio and Streaming View,  discover a wide selection of applications designed for creating impactful material </p> <br/> <br/>
            <ul>
            <li>Sponsor material</li>
            <li>Corporate strategy</li>
            <li>Campaign material</li>
            <li>Product catalogues</li>
            <li>Meeting slide decks  </li>
            <li>Manuals</li>
            </ul>
            `
          }
        />
        <ModulesManuals />
  {width >= 768 ?     <>
      <FlexBanner
          extraClass={"portalsFlex"}
          title={`WiZR Connect - <br/> Video conferencing`}
          image="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680120131/web%20images/WiZRConnect_Meeting_9_1_1_klgujn.png"
          desc="<p>Our video conferencing can be used alone or with other modules. <b><a href='/wizrconnect'>Learn more.</a></b> <br/> <br/> <br/> <b>Enjoy features like: </p></b> <br/> 
            <ul>
            <li>Custom Branding Colors</li>
            <li>Notes and Chat</li>
            <li>Premium Breakout Rooms</li>
            <li>Sound and Emoji Hub</li>
            <li>Multi-Screen Sharing</li>
            <li>Meeting Timers</li>
            <li>Agenda and Polls</li>
             </ul>
            "
        />
        <PdfSection
          extraClass="brightversion2"
          title="Vision for our Product Modules "
        />
        <ModulesArticle />
        <div className={styles.prodValues}>
        <div className={styles.bottomModules}>
          <img
          alt="start up"
            src={
              "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680986643/web%20images/Start-Up_-_-AI-4-isolated_1_phvrhx_jibsxp.png"
            }
          />
        </div>
        <Values
          title="Benefits Overview "
          description="Experience improved productivity and convenience."
          values={values}
          numberColor="#EC7600"
          extraClass="pricingvalues"
          />
          </div>
          </> : null}
            <WhyBanner />
        <Footer />
      </main>
    </>
  );
}
export default ModulesY;
