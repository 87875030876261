export const values = [
    {
        number: "1",
        title: "Save time by up to 10x",
        list:[
            'Easy information overview','Find material faster','Increase reuse of content','Use elegant templates','Create presentations faster','Fewer & shorter meetings'
        ]
    },
    {
        number: "2",
        title: "Improve quality/value",
        list:[
       'Empower your employees','Consistent content','Create & share best practices','Graphic Designer level layouts','Better & more precise dialogue','Video automation'
        ],
    },
    {
        number: "3",
        title: "Improve cost/income",
        list:[
            'Boost productivity','Save meeting time and travels','Always updated and available','Quicker sales cycles','Win more big projects','Communicate to investors'
        ]
    },
    {
        number: "4",
        title: "Content to use",
        list:[
'Keynotes and presentations','Client statements and cases','On/offboarding material','Strategic material','Business plans and other plans','Excel work sheets' 
        ]
    },
]

export const valuesAbout = [
    {
        number: "1",
        title: "Shining Professionally",
        desc: 'Everyone deserve a chance to shine. With us, students, freelancers, consultants, companies and organizations can experience a new level of communication.'
    },
    {
        number: "2",
        title: "Virtual better than Real",
        desc: 'The way we work and communicate is increasingly digital. Our goal is to create an impactful, fun, and productive virtual presence.'
    },
    {
        number: "3",
        title: "Democratize Communications",
        desc: "We took inspiration from the world's best communicators and made the features affordable. With MASTER WiZR you can experience what it means to be a Pro."
    },
    {
        number: "4",
        title: "Diversity is a Superpower",
        desc: 'Virtual work allows for borderless collaboration. We want to make it a new super tool by creating solutions that reflect and stimulate diversity.'
    },
]


export const aboutSlider = [
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703694005/web/Rectangle_14714_2_jtfqyo.jpg",
    },
    // {
    //     image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710788953/Templates/Rectangle_15406_nrj9gf.png"
    // },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703694005/web/Rectangle_14715_1_olyrg2.jpg",
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703694003/web/Rectangle_14712_2_lemanq.jpg",
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703694004/web/Rectangle_14715_2_kry3o7.jpg",
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703694002/web/Rectangle_14712_1_igzrae.jpg",
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703694004/web/Rectangle_14714_4_vzkozh.jpg",
    },
    // {
    //     image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710788953/Templates/Rectangle_15412_a13jfg.png"
    // },
    // {
    //     image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710788954/Templates/Rectangle_15413_j71fsr.png"
    // },
    // {
    //     image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710788953/Templates/Rectangle_15411_wcfqhx.png"
    // },
]
