import React, { useState } from "react";
import * as styles from "./ManualsSection.module.scss";
import { Link } from "gatsby";
import { manualsdata, videosdata } from "../../../constants/manualsData";
import {FaPlay} from "react-icons/fa";
function ModulesManuals({ slicenumber }) {
  const [state, setState] = useState(true);
  const data = state ? videosdata : manualsdata;
  return (
    <div className={styles.manuals}>
      <div className={styles.top}>
        <h1>Visual Tour Videos </h1>
        {/* <p>Also see our Show Room for more details  </p> */}
      </div>
      <div className={styles.main}>
        <div className={styles.container}>
          {videosdata.map((item, i) => {
            return (
              <Link to={item.link}>
                <div key={i} className={`${styles.item} ${styles[item.class]}`}>
                  <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${item.image})` }}
                  >{state && <button className={styles.icon}><FaPlay/></button> }</div>
                  <h2>{item.title}</h2>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default ModulesManuals;
