import React, { useState } from "react";
import * as styles from "./ModulesArticle.module.scss";
import PresSlider from "../../molecules/PresSlider/PresSlider";

function ModulesArticle() {
  const [state2, setState2] = useState(false);
  const toggleFullScreen = () => {
    if (typeof document !== "undefined") {
      if (state2) {
        document?.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
      setState2(!state2);
    }
  };
  return (
    <div className={`${styles.container} presentationsSlider`}>
      <PresSlider state={state2} extraClass={state2 ? `fullslider2` : ``} />
    </div>
  );
}
export default ModulesArticle;
