// extracted by mini-css-extract-plugin
export var Library = "ManualsSection-module--Library--5206b";
export var Studio = "ManualsSection-module--Studio--62cbc";
export var active = "ManualsSection-module--active--98e94";
export var buttons = "ManualsSection-module--buttons--0ca8e";
export var container = "ManualsSection-module--container--f0a25";
export var icon = "ManualsSection-module--icon--3a9a0";
export var image = "ManualsSection-module--image--215dc";
export var item = "ManualsSection-module--item--ec2fd";
export var main = "ManualsSection-module--main--4e44c";
export var manuals = "ManualsSection-module--manuals--b2043";
export var top = "ManualsSection-module--top--d0e0a";
export var wizrconnect = "ManualsSection-module--wizrconnect--3a217";
export var wizrx = "ManualsSection-module--wizrx--8b225";