import React, {useCallback, useEffect, useState} from "react";
import * as styles from './explore.module.scss';
import WizrIcon from '../../../svg/exploreWizr.svg';
import StyledButton from '../../atoms/StyledButton/index';
import {FaAngleRight } from 'react-icons/fa';
const ModulesExplore = () => {
    const [width, setWidth] = useState(0);
    useEffect(() => {
       setWidth(window.innerWidth);
    });
    return(
        <>
{width>=768 ? 
  <div className={styles.container}>
  <div>
      <WizrIcon className={styles.logo} />
  </div>
  <div className={styles.description}>
      <h2>All-in-One Approach</h2>
      <p className={styles.boldText}><b>Preparing, presenting, sharing and connecting better</b></p>
      <p>Looking for an all-in-one solution for your work? MASTER WiZR simplifies your workflow with its specialized Library and Studio module for organizing and sharing material in an elegant manner.<br/><br/> Stay tuned for additional product modules launching throughout 2023, including chat, calendar, news sharing, and presentation creation, all designed to help you connect better. </p>
      {/* <p>We inspire companies and individuals to project themselves with innovative communication solutions. MASTER WiZR is the world’s premier presentation and meeting platform to level the playing field. Now small fish can look big, and big fish can look nimble. <br/><b className={styles.boldText2}>We empower all to Shine Professionally.</b></p> */}
      <StyledButton icon={<FaAngleRight  />} title="About Us" link="/about-us" extraClass="nobgLink"/>
      <hr/>
  </div>
</div>
:

       <div className={styles.mobileContainer}>
       <h1>All-in-One <br/> Approach</h1>
       <div>
           <WizrIcon className={styles.logo} />
       </div>
       <p>MASTER WiZR simplifies workflow with Library & Studio modules. Additional modules coming 2023: chat, calendar, news, and presentations.</p>
   </div>
}
   </>
    );
}

export default ModulesExplore;