import React from 'react';
import * as styles from './value.module.scss';

const Value = ({ title, description,number,numberColor, list,component}) => {
    return (
        <div className={component==="AboutUs" ? `${styles.container} ${styles.aboutValue}` : styles.container}>
            {component==="AboutUs" &&   <div className={styles.number}>
                <p style={{color:numberColor}}>{number}.</p>
            </div>}
            <div className={styles.text}>
                <h4>{title}</h4>
               {description && <p>{description}</p>}
                {list && <ul className={styles.list}>
                    {list.map((item) => {
                        return <li><div className={styles.circle}></div>{item}</li>;
                    })}
                </ul>}
            </div>
        </div>
    );
};

export default Value;
